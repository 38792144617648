const initialState = {
	referralPopup: false,
	appDownload: false,
	isLoginFlow: false,
	isResetPasswordFlow: false
};

const usePopupsState = () => {
	const popupState = useState("popupState", () => initialState);

	const resetPopupState = () => {
		popupState.value = initialState;
	};

	return {
		popupState,
		resetPopupState
	};
};

export default usePopupsState;
